import React, { Fragment } from 'react'
import {
  MenuHeader,
  MenuInline,
  MenuWrapper,
  MenuSubWrapper,
  MenuWrapperRight,
  MenuFirst,
  MenuFirstList,
  MenuSecond,
  MenuSecondList,
  MenuThird,
  MenuThirdList,
  MenuHeaderMobile,
} from './styles'
import { white, blue, black, breakpoints } from '../../assets/styles/styles'
import {
  Text,
  Absolute,
  Flex,
  Icon,
  Relative,
  Separator,
  Overlay,
} from '../../assets/styles/components'
import Masonry from 'react-masonry-component'
import MediaQuery from 'react-responsive'

const masonryOptions = {
    transitionDuration: 0,
}
  
const imagesLoadedOptions = { background: '.my-bg-image-el' }

export default function SubMenu({categories, subcategories, subthirdcategories, secondopen, open, third, changeState, catdigital, subcategory, open1, secondopen1, capitalizeWords }) {
  return (
        <>
          {open ? (
            <div className='submenu-list'>
            <MenuSubWrapper
              onMouseLeave={() => {
                changeState('third', '')
                changeState('subcategory', [])
                changeState('open', false)
              }}
            //   ref={(ref) => (this.wrapper = ref)}
            >
              <MenuSecond
                onMouseEnter={() => {
                  changeState('secondopen', true)
                }}
                onMouseLeave={() => {
                  changeState('secondopen', false)
                }}
              >
                {categories.map((g, i) => (
                  <MenuSecondList
                    key={i}
                    onMouseEnter={() => {
                      changeState('third', g)
                      changeState('subcategories', g.child)
                    }}
                    onClick={() => {
                      changeState('open', false)
                     
                    }}
                    className={g.name === third.name ? 'active' : ''}
                  >
                    <a href={`/subcategory/${g.slug}`}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="13px" isTitle="true">
                          {g.name.toUpperCase()}
                        </Text>
                        
                      </Flex>
                      
                    </a>
                  </MenuSecondList>
                ))}
                <MenuSecondList
                  onClick={() => {
                    if (this.props.location.pathname == '/brand') {
                      changeState('open', false)
                      return false
                    }
                    this.props.context.state.history.push('/brand')
                  }}
                  onMouseEnter={() => {
                    changeState('third', '')
                    changeState('subcategories', [])
                  }}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="14px" isTitle="true">
                      BRAND
                    </Text>
                    <Icon
                      className="fa fa-chevron-right"
                      style={{ fontSize: 12, color: white }}
                    />
                  </Flex>
                  
                </MenuSecondList>
              </MenuSecond>
              {subcategories.length > 0 ? (
                subcategories.map((g, i) => (
                  <MenuThird
                      key={i}
                      onMouseEnter={() => {
                        changeState('secondopen', true)
                      }}
                    >
                      <a href={`/subcategory/${g.slug}`}><Text
                        margin="0 0 5px"
                        isTitle="true"
                        fontWeight="bold"
                        fontSize="12px"
                        hoverStyle={{ color: blue }}
                      >
                        {g.name.toUpperCase()}
                      </Text></a>
                      <Masonry
                        className={'masonry'} // default ''
                        elementType={'div'} // default 'div'
                        options={masonryOptions} // default {}
                        disableImagesLoaded={false} // default false
                        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                        imagesLoadedOptions={imagesLoadedOptions} // default {}
                      >
                        {g.subchild.length > 0 ? (
                          g.subchild.map((gsub, i) => (
                        <MenuThirdList key={i}>
                          <a href={`/subcategory/${gsub.slug}`}>
                            <Text
                              fontSize="12px"
                              // fontWeight="bold"
                              margin="0"
                              cursor="pointer"
                              hoverStyle={{ color: blue }}
                              isTitle="true"
                              onClick={() => {
                               
                                changeState('open', false)
                              }}
                            >
                              {gsub.name}
                            </Text>
                          </a>
                         
                        </MenuThirdList>
                      ))
                    ) : (
                      <Text fontSize="14px" margin="0 0 5px" isTitle="true">
                        No category found
                      </Text>
                    )}
                      </Masonry>
                    </MenuThird>
                  ))
              ) : null}
            </MenuSubWrapper></div>
          ) : null}
        {open1 ? (
          <div className='submenu-list'>
            <MenuWrapperRight
              onMouseLeave={() => {
                changeState('subcategory', [])
                changeState('open1', false)
              }}
            //   ref={(ref) => (this.wrapper = ref)}
            >
               <MenuSecond
                onMouseEnter={() => {
                  changeState('secondopen1', true)
                }}
                onMouseLeave={() => {
                  changeState('third', '')
                  changeState('secondopen1', false)
                }}
              >
                {catdigital.map((g, i) => (
                  <MenuSecondList
                    key={i}
                    onMouseEnter={() => {
                      changeState('subcategory', g.subcategory)
                    }}
                    onClick={() => {
                      changeState('open1', false)
                     
                    }}
                    
                  >
                    <a href={`/productdigital?parent=${g.slug}`}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="14px" isTitle="true" >
                          {capitalizeWords(g.name)}
                        </Text>
                        <Icon
                          className="fa fa-chevron-right"
                          style={{ fontSize: 12, color: white }}
                        />
                      </Flex>
                      
                    </a>
                  </MenuSecondList>
                ))}
              
              </MenuSecond>
              {subcategory.length > 0 ? (
                <MenuThird
                  onMouseEnter={() => {
                    changeState('secondopen1', true)
                  }}
                >
                 
                  <Masonry
                    className={'masonry'} // default ''
                    elementType={'div'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                    imagesLoadedOptions={imagesLoadedOptions} // default {}
                  >
                    {subcategory ? (
                      subcategory.map((g, i) => (
                        <MenuThirdList key={"1"}>
                          <a href={`/productdigital?child=${g.slug}`}>
                            <Text
                              fontSize="14px"
                              // fontWeight="bold"
                              margin="0"
                              cursor="pointer"
                              hoverStyle={{ color: blue }}
                              isTitle="true"
                              onClick={() => {
                            
                                changeState('open1', false)
                              }}
                            >
                              {capitalizeWords(g.name)}
                            </Text>
                          </a>
                        
                        </MenuThirdList>
                      ))
                    ) : (
                      <Text fontSize="14px" margin="0 0 5px" isTitle="true">
                        No category found
                      </Text>
                    )}
                  </Masonry>
                </MenuThird>
              ) : null}
            </MenuWrapperRight></div>
          ) : null}
          </>
  )
}
