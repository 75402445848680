import React, { Fragment } from 'react'
import { withContext } from '../../utils/helper'
import { HeaderTop, HeaderStyled } from './styles'
import {
  Flex,
  Image,
  FlexCell,
  SeparatorVertical,
  Relative,
  Text,
} from '../../assets/styles/components'
import InputOutlined from '../Input/InputOutlined'
import Badge from '../Badge/Badge'
import Menu from '../Menu/Menu'
import ButtonLink from '../Button/ButtonLink'
import { Link } from 'react-router-dom'
import Cart from '../Cart/Cart'
import Search from '../Search/Search'
import Notification from '../Notification/Notification'
import User from '../User/User'
import { white, breakpoints } from '../../assets/styles/styles'
import { Row, Col, Container } from '../Bootstrap/Bootstrap'
import MediaQuery from 'react-responsive'
import HeaderMobile from './HeaderMobile'
import { Promo } from '../../routes/Home/styles'
import ajaxHome from '../../routes/Home/ajax'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenu: false,
      mobileSearch: false,
    }
  }
  componentDidMount() {
    this.fetchData()
    window.addEventListener('resize', this.updateSize)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.context.state.promo != this.props.context.state.promo) {
      this.updateSize()
    }
  }

  updateSize = () => {
    const height = this.promoEl.clientHeight
    this.props.context.update('promoHeight', height)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize)
  }

  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async fetchData() {
    const { token, refetchPromo } = this.props.context.state
    if (!refetchPromo) return false
    this.setState({ loading: true, errordata: false })
    let res = await ajaxHome.getPromoText(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    this.props.context.update('promo', res.data)
    this.props.context.update('refetchPromo', false)
  }
  render() {
    const { state, update } = this.props.context
    const { promo } = state
    return (
      <HeaderTop>
        {promo.content ? (
          <Container fluid>
            <Row>
              <Col
                md={12}
                padding="0"
                style={{
                  backgroundImage: `url('${promo.picture}')`,
                }}
              >
                <Promo
                  className='promo-text'
                  href={promo.link}
                  target="_blank"
                  style={{ color: promo.hex_code }}
                  ref={(ref) => {
                    this.promoEl = ref
                  }}
                >
                  {promo.content}
                </Promo>
              </Col>
            </Row>
          </Container>
        ) : null}
        <HeaderStyled fluid>
          <MediaQuery minWidth={breakpoints[0]}>
            <Container>
            <Row alignItems="center">
              <Col md={{ span: 3, order: 1 }}  xs={{ span: 12, order: 1 }}>
                <Flex justifyContent="left">
                  <Link
                    to="/"
                    onClick={() => {
                      if (this.props.location.pathname === '/')
                        window.location.reload()
                    }}
                  >
                    <Image
                      src={require('../../assets/images/logo-new1.png')}
                      
                      width={140}
                      mobileStyle={{ width: '100px' }}
                    />
                  </Link>
                </Flex>
              </Col>
              <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                <Search
                  location={this.props.location}
                  match={this.props.match}
                />
              </Col>
             
              <Col md={{ span: 3, order: 3 }} xs={{ span: 12, order: 2 }}>
                <Flex
                  justifyContent="flex-end"
                  mobileStyle={`
								justify-content: center;
								margin-top: 10px;
								padding: 0 15px;
							`}
                >
                  {state.token ? (
                    <Fragment>
                      <Cart />
                      <SeparatorVertical margin="0 15px" borderColor="#f27b3a" />

                      <Notification />
                      <SeparatorVertical margin="0 10px" borderColor="#f27b3a"/>

                      <User />
                    </Fragment>
                  ) : (
                    <Flex>
                      <Text
                        onClick={() => update('showLogin', true)}
                        cursor="pointer"
                        fontSize="15px"
                      >
                        Login
                      </Text>
                      {/* Disabled Date Register 21/06/2024 */}
                      {/* <ButtonLink
                        to="/register"
                        color="white"
                        padding="6px 20px"
                        margin="0 10px"
                      >
                        Register
                      </ButtonLink> */}
                      <span style={{ margin: '0 10px', color: '#4a4a4a' }}>|</span>
                      <Link to="/register">
                        <Text
                          cursor="pointer"
                          fontSize="15px"
                        >
                          Register
                        </Text>
                      </Link>
                    </Flex>
                  )}
                </Flex>
              </Col>
             </Row>
            </Container>
          </MediaQuery>

          <MediaQuery maxWidth={breakpoints[0]}>
            <HeaderMobile
              mobileMenu={this.state.mobileMenu}
              mobileSearch={this.state.mobileSearch}
              changeState={this.changeState}
              location={this.props.location}
            />
          </MediaQuery>
        </HeaderStyled>

        <Menu
          match={this.props.match}
          mobileMenu={this.state.mobileMenu}
          mobileSearch={this.state.mobileSearch}
          changeState={this.changeState}
          location={this.props.location}
          match={this.props.match}
        />

        
      </HeaderTop>
    )
  }
}

Header.defaultProps = {}

export default withContext(Header)
