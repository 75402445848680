import styled from '@emotion/styled'
import { blue, white, breakpoints, orange } from '../../assets/styles/styles'

const MenuHeader = styled.ul`
  margin: 0;
  padding: 0 2em;
  background-color: ${white};
  position: relative;
  box-shadow: 0 1px 10px 0 rgba(155, 155, 155, 0.3);
  border-bottom: 1px solid #d2d3d4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  // @media (max-width: ${breakpoints[0]}) {
  //   display: none;
  // }
`

const MenuHeaderMobile = styled.div`
  // display: none;
  background-color: ${blue};
  padding: 10px 15px;

  // @media (max-width: ${breakpoints[0]}) {
  //   display: block;
  // }
`

const MenuListWrapper = styled.div`
  width: 50%;
  background-color: ${white};
  z-index: 21;
  position: relative;
`

const MenuInline = styled.li`
  list-style: none;
  margin: 0 16px;
  cursor: pointer;
  padding: 8px;
  &:hover {
    .submenu-list{
      display: block;
    }
  }
`

const MenuWrapper = styled.div`
  position: absolute;
  left: 180px;
  top: 30px;
  z-index: 11;
  display: flex;
  width: 100%;
  height: auto;
  @media screen and (min-width: 1024px) {
    top: 34px;
    left:50px;
  }
  @media screen and (min-width: 1440px) {
    top: 34px;
    left:300px;
  }
  @media screen and (min-width: 1680px) {
    top: 34px;
    left:400px;
  }
  @media screen and (min-width: 1920px) {
    top: 34px;
    left:500px;
  }
  @media screen and (min-width: 2560px) {
    top: 34px;
    left:800px;
  }
`

const MenuSubWrapper = styled.div`
  position: absolute;
  top: 38px;
  z-index: 10;
  display: flex;
  width: 100%;
  height: auto;
`

const MenuWrapperRight = styled.div`
  position: absolute;
  top: 38px;
  z-index: 10;
  display: flex;
  width: 100%;
  height: auto;
`

const MenuFirst = styled.ul`
  background-color: ${white};
  margin: 0;
  padding: 0;
  width: 17%;
  border-right: 1px solid #ddd;
  overflow: auto;
  box-shadow: 0 1px 20px 0 rgba(155, 155, 155, 0.3);
  @media (max-width: ${breakpoints[0]}) {
    width: 100%;
  }
`
const MenuFirstList = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  &:hover{
    background-color: ${blue};
    color: ${white};
    div {
      color: ${white};
    }
  }
`
const MenuSecond = styled.ul`
  background-color: ${white};
  margin: 0;
  padding: 12px 10px;
  width: 18%;
  border-right: 1px solid #ddd;
  overflow: auto;

  @media (max-width: ${breakpoints[0]}) {
    width: 100%;
  }
  
`
const MenuSecondList = styled.li`
  cursor: pointer;
  padding: 3px 8px;
  margin: 2px 0;
  width: 100%;
  list-style: none;
  &:hover,
  &.active {
    font-weight: bold;
    border-radius: 10px;
    
  }
  a:hover {
    text-decoration: none;
  }
`

const MenuThird = styled.ul`
  background-color: ${white};
  margin: 0;
  padding: 20px 25px;
  width: auto;
  min-width: 15%;
  overflow: auto;
  box-shadow: 0px 1px 4px 0px rgba(155, 155, 155, 0.3);

  @media (max-width: ${breakpoints[0]}) {
    padding: 12px 15px;
    width: 100%;
  }
  a:hover {
    text-decoration: none;
    color: ${blue};
  }
`

const MenuThirdList = styled.li`
  list-style: none;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 3px;

  a:hover {
    text-decoration: none;
  }

  @media (max-width: ${breakpoints[0]}) {
    width: 48%;
  }
`

const MenuMobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  background-color: transparent;
  z-index: 20;
  top: 0;
  left: ${props => (props.open ? '0' : '100%')};
  transition: all 0.2s;
`

const MenuMobileList = styled.div`
  padding: 12px 15px;
`

export {
  MenuHeader,
  MenuHeaderMobile,
  MenuListWrapper,
  MenuInline,
  MenuWrapper,
  MenuWrapperRight,
  MenuFirst,
  MenuFirstList,
  MenuSecond,
  MenuSecondList,
  MenuThird,
  MenuThirdList,
  MenuMobileWrapper,
  MenuMobileList,
  MenuSubWrapper,
}
