import styled from '@emotion/styled'
import { blue } from '../../assets/styles/styles'

const CheckboxWrapper = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  vertical-align: middle;
  margin-bottom: 0;
  input:checked ~ span {
    background-color: ${blue};
    border-color: ${blue};
  }
  input:checked ~ span:after {
    display: block;
  }
`

const CheckboxStyled = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  left: 0 !important;
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #cccc;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: 40%;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

const CheckboxText = styled.label`
  color: #555;
  margin-left: 10px;
  font-weight: normal;
  cursor: pointer;
  margin-bottom: 0;
  vertical-align: middle;
  line-height: 1;
  font-size: 12px;
`

export { CheckboxWrapper, CheckboxStyled, Checkmark, CheckboxText }
